// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAikiTAQI88KMQpeU_ifabvGceZv-xAccs",
  authDomain: "kidmetrix.firebaseapp.com",
  projectId: "kidmetrix",
  storageBucket: "kidmetrix.appspot.com",
  messagingSenderId: "793619490462",
  appId: "1:793619490462:web:21a67e6644b1c5725f3d8f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, GoogleAuthProvider, signInWithPopup, firestore };
