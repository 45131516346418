// src/components/GoogleSignIn.vue
<template>
  <button @click="signInWithGoogle">Sign In with Google</button>
</template>

<script>
import { auth, GoogleAuthProvider, signInWithPopup } from "../../firebase.js";

export default {
  methods: {
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        // User signed in
        const user = result.user;
        console.log("User info:", user);
      } catch (error) {
        console.error("Error signing in with Google:", error);
      }
    },
  },
};
</script>
